<template>
  <div class="login-box fade-in">
    <div class="login-logo">
      <a v-bind:href="website">
        <img v-bind:src="img_banner" style="width: 90%;" />
      </a>
    </div>

    <div class="login-box-body">
      <div>
        <p class="login-box-msg text-warning" v-if="error">
          <i class="fa fa-exclamation-triangle"></i>
          {{ $tc(error) }}
        </p>
        <p class="login-box-msg" v-else>
          {{ $t("please_fill_this_form_to_require_password_reset") }}
        </p>
        <form
          action="/dashboard"
          accept-charset="UTF-8"
          method="post"
          autocomplete="off"
          v-on:submit.prevent="onSubmit"
        >
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group has-feedback">
                <label for="usr">{{ $t("email") }}</label>
                <input
                  name="usr"
                  type="text"
                  data-testid="email"
                  class="form-control"
                  :placeholder="$tc('email')"
                  required
                  v-model="usr"
                  ref="usr"
                  autocomplete="off"
                />
                <span class="fa fa-envelope form-control-feedback"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- /.col -->
            <div class="col-xs-4 col-xs-offset-4">
              <button
                type="submit"
                data-testid="submit"
                class="btn btn-primary btn-block"
                v-bind:disabled="!isValid"
              >
                {{ $t("confirm") }}
              </button>
            </div>
            <!-- /.col -->
          </div>
        </form>
      </div>
      <!-- /.social-auth-links -->
      <div class="row row-top-gap">
        <div class="col-xs-12">
          <router-link to="/" data-testid="login-link">
            <i class="fa fa-arrow-left"></i>
            {{ $t("back") }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="overlay" v-if="busy">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
  </div>
</template>

<script>
import greCAPTCHA from "@/plugins/grecaptcha.js";
import AuthService from "@/services/auth";

export default {
  name: "FormUserPasswordRequestLink",
  components: {},
  props: {},
  data: function() {
    return {
      usr: "",
      busy: false,
      grecaptcha: null
    };
  },
  computed: {
    isReady: function() {
      return this.$store.getters["dashboard/isReady"];
    },
    img_banner: function() {
      return (
        this.$store.getters["dashboard/property"]("company_banner") ||
        "/static/common/images/hitecnologia_logo.png"
      );
    },
    website: function() {
      return (
        this.$store.getters["dashboard/property"]("company_website") ||
        "https://www.hitecnologia.com.br"
      );
    },
    error: function() {
      return this.$store.getters["user/loginError"];
    },
    isNameValid() {
      return (
        this.first_name && this.last_name && this.first_name != this.last_name
      );
    },
    isPwdValid() {
      return this.pwd1 && this.pwd2 && this.pwd1 == this.pwd2;
    },
    isValid() {
      return this.usr;
    },
    payload() {
      let payload = {
        email: this.usr
      };
      return payload;
    },
    g_site_token() {
      return (
        this.$store.getters["dashboard/property"]("grecaptcha_site_token") || ""
      );
    }
  },
  watch: {},
  methods: {
    onError(details) {
      let self = this;
      self.$swal({
        title: self.$t("an_error_has_occurred"),
        text: details,
        icon: "error"
      });
    },
    onSuccess(callback) {
      let self = this;
      let _cb = callback || function() {};
      self
        .$swal({
          title: self.$t("done"),
          text: self.$t("password_reset_request_success"),
          icon: "success"
        })
        .then((result) => {
          _cb();
        });
    },
    save(token) {
      let self = this;
      self.busy = true;
      var payload = self.payload;
      if (token) {
        payload.grecaptcha = token;
      }
      let auth = new AuthService();
      auth.request_password_link(payload).then((response) => {
        self.busy = false;
        if (response) {
          if (typeof response == "object") {
            self.onSuccess(function() {
              self.$router.push("/");
            });
          } else {
            self.onError(response);
          }
        } else {
          self.onError(self.$t("sorry_we_could_not_perform_your_request"));
        }
      });
    },
    onSubmit(e) {
      var self = this;
      if (self.isValid) {
        if (self.grecaptcha) {
          self.grecaptcha
            .execute(self.g_site_token, { action: "register" })
            .then(function(token) {
              self.save(token);
            });
        } else {
          self.save();
        }
      }
    }
  },
  created() {
    let self = this;
    self.grecaptcha = null;
    if (self.g_site_token) {
      (async function() {
        self.grecaptcha = await greCAPTCHA(self.g_site_token);
      })();
    }
  },
  mounted() {
    this.$nextTick(() => this.$refs.usr.focus());
  }
};
</script>

<style scoped>
.login-logo {
  min-height: 70px;
}

.login-box {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 450px;
}

.login-box-body,
.register-box-body {
  border: 1px solid #dcdada;
  padding: 20px;
  border-radius: 4px;
}

.form-check span {
  vertical-align: top;
}

.login-links {
  line-height: 2em;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.login-box .overlay > .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  color: #000;
  font-size: 30px;
}

.login-box .overlay {
  z-index: 50;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
}
.row-top-gap {
  margin-top: 20px;
}
</style>
